<template>
  <pre-loader v-if="isLoading"></pre-loader>
  <div v-else>
    <div v-if="!noCandidate">
      <template-one v-if="candidate.profileTheme === 1"></template-one>
      <template-two v-if="candidate.profileTheme === 2"></template-two>
      <template-three v-if="candidate.profileTheme === 3"></template-three>
      <template-four v-if="candidate.profileTheme === 4"></template-four>
      <template-five v-if="candidate.profileTheme === 5"></template-five>
      <template-six v-if="candidate.profileTheme === 6"></template-six>
    </div>
    <not-found v-else></not-found>
  </div>
</template>

<script>
import TemplateOne from "@/views/public/Template1";
import TemplateTwo from "@/views/public/Template2";
import TemplateThree from "@/views/public/Template3";
import TemplateFour from "@/views/public/Template4";
import TemplateFive from "@/views/public/Template5";
import TemplateSix from "@/views/public/Template6";
import NotFound from "@/views/404";
import PreLoader from "@/components/common/PreLoader";
import Candidate from "@/models/Candidate";
import * as CandidateService from "@/services/CandidateService2";

export default {
  name: "candidate",
  data() {
    return {
      candidate: new Candidate(),
      noCandidate: false,
      isLoading: true,
      description: "",
    };
  },
  components: {
    TemplateOne,
    TemplateTwo,
    TemplateThree,
    TemplateFour,
    TemplateFive,
    TemplateSix,
    NotFound,
    PreLoader,
  },
  created() {
    CandidateService.GetCandidateByNameAsync(this.$route.params.candidate)
      .then((res) => {
        this.candidate = new Candidate(res.data);
        this.$store.commit("candidates/SET_CANDIDATE", res.data);
      })
      .catch(() => {
        this.noCandidate = true;
      })
      .finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      });
  },
  metaInfo() {
    return {
      title: `WGA Candidate ${this.$route.params.year} - ${this.candidate.name}`,
      meta: [
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },
  // updated() {
  //   this.description = "";
  //   let lastIndex = 149;

  //   if (/^[a-zA-Z]+$/.test(this.candidate.introText[lastIndex])) {
  //       while (/^[a-zA-Z]+$/.test(this.candidate.introText[lastIndex])) {
  //           ++lastIndex;
  //       }
  //   }

  //   for (let index = 0; index < lastIndex; index++) {
  //       const element = this.candidate.introText[index];
  //       this.description += element;
  //   }

  //   this.description = this.description.replace(/<[^>]*>?/gm, "")

  // }
};
</script>
